<script setup lang="ts">
import { NuxtLink } from '#components'

defineProps({
  articles: {},
  tags: {},
  title: {
    default: 'Статьи и лайфхаки',
  },
})

const $router = useRouter()
</script>

<template>
  <div class="main-blog container">
    <h2 class="main-blog__title headline-2">
      {{ title }}
    </h2>
    <p class="main-blog__subtitle title-2">
      Полезные статьи о провайдерах и интернет-технологиях, обзоры и сравнения услуг операторов, их плюсы и минусы, а также экспертные рекомендации от нашей команды
    </p>
    <div class="main-blog__tags d-flex">
      <component
        :is="tag.no_index ? 'button' : NuxtLink"
        v-for="tag in tags"
        :key="tag.id"
        class="main-blog__tag white--bg black--text d-flex align-center text-no-wrap"
        :to="tag.no_index ? undefined : `/blog/category/${tag.slug}/`"
        @click="tag.no_index ? $router.push(`/blog/category/${tag.slug}/`) : null"
      >
        <img
          :src="tag.icon"
          alt="icon"
        >
        {{ tag.name }}
      </component>
    </div>
    <div class="main-blog__cards">
      <NuxtLink
        v-for="card in articles.results"
        :key="card.id"
        class="main-blog__card white--bg d-flex flex-column"
        :to="`/blog/${card.slug}/`"
      >
        <div class="main-blog__card-top d-flex">
          <img
            class="main-blog__card-img"
            :src="card.preview_image"
            :alt="card.slug"
          >
        </div>
        <div class="main-blog__card-body d-flex flex-column">
          <p class="main-blog__card-date subhead-3 gray-dark--text">
            {{ new Date(card.created).toLocaleDateString("ru-RU", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            }) }}
          </p>
          <p class="main-blog__card-title title-3 black--text">
            {{ card.title }}
          </p>
          <div
            v-if="card.tags?.[0]"
            class="main-blog__card-tag d-flex align-center black--text subhead-3 gray-p--bg"
          >
            <img
              :src="card.tags[0].icon"
              :alt="card.tags[0].slug"
            >
            {{ card.tags[0].name }}
          </div>
        </div>
      </NuxtLink>
    </div>
    <div class="main-blog__showAll d-flex justify-end">
      <NuxtLink
        :to="`/blog/`"
        class="primary--text title-5 d-flex align-center main-blog__showAll-link"
      >
        Все статьи
        <span
          style="transform: rotate(-90deg)"
          class="mask-icon mask-arrow-expand mask-size-lg d-flex"
        />
      </NuxtLink>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main-blog {
  padding-top: 80px;
  padding-bottom: 32px;
  @media (max-width: getBreakpoint(desktop)) {
    padding-top: 64px;
    padding-bottom: 16px;
  }
  @media (max-width: getBreakpoint(tablet)) {
    padding-top: 48px;
  }

  .mask-arrow-expand {
    mask-image: url('@/assets/images/svg/arrow-expand.svg');
  }

  &__title {
    margin-bottom: 8px;
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-bottom: 4px;
    }
  }

  &__subtitle {
    margin-bottom: 24px;
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 16px;
    }
  }
  &__tags {
    margin-bottom: 24px;
    @media (max-width: 1280px) {
      overflow: scroll;
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      &::-webkit-scrollbar-thumb {
        display: none;
      }
    }
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 16px;
    }
    @media (max-width: getBreakpoint(mobile-lg)) {
      margin-bottom: 12px;
    }
    @media (max-width: getBreakpoint(mobile-md)) {
      margin-left: -8px;
      margin-right: -8px;
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  &__tag {
    border-radius: 8px;
    padding: 8px;
    margin-right: 8px;
    flex-shrink: 0;
    transition: color .24s;

    &:hover {
      color: color(primary);
    }
    img {
      margin-right: 4px;
      width: 24px;
      height: 24px;
    }
  }

  &__cards {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    @media (max-width: getBreakpoint(desktop)) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: getBreakpoint(tablet)) {
      grid-template-columns: repeat(1, 1fr);
      gap: 12px;
    }
  }
  &__card {
    border-radius: 16px;

    &:hover {
      .main-blog__card-title {
        color: color(primary);
      }
    }

    &-top {
      border-radius: 16px 16px 0 0;
      overflow: hidden;
      width: 100%;
      aspect-ratio: 2 / 1.15;
      @media (max-width: getBreakpoint(tablet)) {
        aspect-ratio: 2 / 1;
      }
    }
    &-img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    &-body {
      padding: 12px 16px 24px;
      border-radius: 0 0 16px 16px;
      flex-grow: 1;
      @media (max-width: getBreakpoint(mobile-md)) {
        padding: 12px 12px 16px;
      }
    }
    &-date {
      margin-bottom: 8px;
    }
    &-title {
      margin-bottom: 8px;
      transition: color .24s;
    }
    &-tag {
      margin-top: auto;
      margin-right: auto;
      padding: 0 4px;
      border-radius: 4px;
      img {
        width: 24px;
        height: 24px;
        margin-right: 4px;
      }
    }
  }

  &__showAll {
    margin-top: 24px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-top: 16px;
    }
    .mask-icon {
      margin-left: 4px;
    }

    &-link {
      transition: color .24s;

      &:hover {
        color: color(primary-dark);
      }
    }
  }
}
</style>
